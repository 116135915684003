<template>
    <b-card
        :title="cardtitle"
        style="max-width: 20rem"
        class="text-center"
        text-variant="white"
        :bg-variant="bgvariant"
    >
        <b-card-text style="white-space: pre-wrap;">{{ cardtext }}</b-card-text>
    </b-card>
</template>

<script>
export default {
    props: {
        cardtitle: String,
        cardtext: String,
        bgvariant: String,
    },
    /*
    data() {
        return {
            bgColor: String,
        }
    },
    created() {
        this.bgColor = this.getBgColor(this.bgvariant);
    },
    methods: {
        getBgColor(bgvariant) {
            switch (bgvariant) {
                case 'online':
                    return '#305d2a';
                case 'loading':
                    return '#918441';
                case 'unloading':
                    return '#419189';
                case 'offline':
                    return '#90414b';
            }
        }
    },*/
};
</script>

<style>
</style>
