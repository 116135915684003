<template>
    <b-container class="dashboard" fluid="" style="margin-left: 250px; width: 80%;">
        <b-container
            class="pt-3 pb-2 mb-3 border-bottom border-dark">
            <b-form-row>
                <b-col cols="6">
                    <h1 class="h2">Dashboard</h1>
                </b-col>

                <!-- Legend -->
                <b-col>
                    <h5>
                        Online:
                    </h5>
                </b-col>
                <b-col>
                    <b-icon icon="square-fill" variant="online" style="width: 25px; height: 25px;"></b-icon>
                </b-col>
                <b-col>
                    <h5>
                        Laden:
                    </h5>
                </b-col>
                <b-col>
                    <b-icon icon="square-fill" variant="loading" style="width: 25px; height: 25px;"></b-icon>
                </b-col>
                <b-col>
                    <h5>
                        Entladen:
                    </h5>
                </b-col>
                <b-col>
                    <b-icon icon="square-fill" variant="unloading" style="width: 25px; height: 25px;"></b-icon>
                </b-col>
                <b-col>
                    <h5>
                        Offline:
                    </h5>
                </b-col>
                <b-col>
                    <b-icon icon="square-fill" variant="offline" style="width: 25px; height: 25px;"></b-icon>
                </b-col>
            </b-form-row>
        </b-container>

        <b-container>
            <b-row
                v-if="cardData.length > 0"
            >
                <b-col
                    v-for="data in cardData"
                    :key="data.title"
                    class="p-2 d-flex align-items-stretch"
                    md="4" lg="3"
                >
                    <dashboard-card
                        :cardtitle="data.title"
                        :cardtext="data.text"
                        :bgvariant="data.status"
                    ></dashboard-card>
                </b-col>
            </b-row>
            <b-row
                v-else
            >
                <b-col>
                    <b-skeleton-img
                        no-aspect
                        height="242px"
                    />
                </b-col>
                <b-col>
                    <b-skeleton-img
                        no-aspect
                        height="242px"
                    />
                </b-col>
                <b-col>
                    <b-skeleton-img
                        no-aspect
                        height="242px"
                    />
                </b-col>
                <b-col>
                    <b-skeleton-img
                        no-aspect
                        height="242px"
                    />
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <b-row>
                <b-col>
                    <dashboard-bar-chart/>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
import DashboardCard from "@/components/DashboardCard.vue";
import DashboardBarChart from "@/components/charts/DashboardBarChart.vue";


export default {
    components: {
        DashboardCard,
        DashboardBarChart,
    },
    data() {
        return {
            cardData: [],
        }
    },
    async created() {
        this.$api
            .get("dashboard/cards", {
                params: {},
            })
            .then((response) => {
                this.cardData = response.data;
            })
            .catch(() => {
                return [];
            });
    }
};
</script>

<style>
</style>
