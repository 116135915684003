<template>
    <b-row class="mt-5">
        <b-col>
            <bar-chart
                v-if="dataSets.length > 0"
                :datasets="dataSets"
                :height="200"
                :labels="chartLabels"
                :options="chartOptions"
            />
            <b-skeleton-img
                v-else
                no-aspect
                height="555px"
            >
            </b-skeleton-img>
        </b-col>
    </b-row>
</template>

<script>
import BarChart from "@/components/BarChart.vue";


export default {
    components: {
        BarChart,
    },

    props: {
        license_plate: {
            type: String,
        },
        start: {
            type: String,
        },
        end: {
            type: String,
        },
    },

    data() {
        return {
            bgColorList: ['#904180', '#6B4190', '#414B90'],
            dataSets: [],
            chartLabels: [],

            chartOptions: {
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'index',
                    intersect: false
                },
                animation: {
                    duration: 0
                },
                responsive: true,
                stacked: false,
                title: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        stacked: true,
                        type: "linear",
                        display: true,
                        position: "left",
                        ticks: {
                            beginAtZero: true,
                            fontColor: "#707070"
                        }
                    }],
                    xAxes: [{
                        stacked: true,
                    }]
                }
            }
        };
    },
    async created() {
        this.$api
            .get("dashboard/chart", {
                params: {},
            })
            .then((response) => {
                this.chartLabels = response.data.labels;

                let i = 0;
                response.data.data.forEach(d => (
                    this.dataSets.push(
                        {
                            label: d.license_plate,
                            data: d.data,
                            backgroundColor: this.bgColorList[i],
                            hoverBackgroundColor: this.bgColorList[i],
                        },
                    ),
                        i++
                ));

            })
            .catch(() => {
                return [];
            });
    },
};
</script>

<style scoped>
</style>
